.fb-lightbox-images-player {
  max-width: 100%;
}

$fb-player-background-color: #201704;

.fb-player-bg-color {
  background-color: $fb-player-background-color;
}

.body_player {
  margin: 0px;
  justify-content: center;
}

.song-image {
  height: 135px;
  width: auto;
  border: 1px solid rgb(94, 94, 94);
}

.band_Name {
  color: #eeeeee;
  font-family: $verdana;
  font-size: 12px;
}

.fb-song-title {
  color: #eeeeee;
  font-size: 18px;
  font-family: $oswald;
}

.fb-song-time {
  color: #eeeeee;
  font-size: 10px;
  font-family: $verdana;
}

.audio-player-cont {
  padding: 5px 0 0 5px;
  display: flex;
  width: 100%;
}

.fb-audio-player-bar {
  padding: 0 10px 0 10px;
  width: 100%;
}

.song-slider {
  width: 100%;
  cursor: pointer;
}

.playpause {
  color: #a12121;
  cursor: pointer;
  padding: 4px 6px 0 6px;
}

.backward-icon,
.forward-icon {
  color: #216ea1;
  cursor: pointer;
  padding-top: 8px;
}

.forward-icon {
  margin-right: 8px;
}

.randomclass {
  display: none;
}

.radom-icon {
  cursor: pointer;
  margin-left: 10px;
  padding-top: 5px;
}

.fbp_repeat_class {
  display: none;
}

.fbp-repeat-icon {
  cursor: pointer;
  margin-left: 10px;
}

.check_list {
  display: none;
}

.listonoffclass {
  cursor: pointer;
  padding-top: 2px;
}

.playlistrow {
  padding: 0px 6px 6px 6px;
}

.play-list-row {
  overflow: auto;
  display: block;
}

.row::after {
  content: '';
  clear: both;
  display: table;
}

.div_play_list {
  box-sizing: border-box;
}

.div_play_list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: $verdana;
}

.div_play_list li {
  padding: 0px;
  margin-bottom: 4px;
  background-color: #3b3b3b;
  color: #ffffff;
  display: flex;
  cursor: pointer;
}

.div_play_list li:hover {
  background-color: #4f4f4f;
}

.div_play_list span {
  padding: 7px 0 0 12px;
}

.div_play_list img {
  height: 30px;
  width: 30px;
}

/*Chrome & Edge*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .body_player input[type='range'] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #201704;
    border-radius: 10px;
  }
}

.body_player input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #c3c2c5;
  margin-top: -1px;
  background-color: #b9b9b9;
}

.body_player input[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  background: #777777;
  box-shadow: -100vw 0 0 100vw #a12121;
  border-radius: 10px;
}

/** FF*/
.body_player input[type='range']::-moz-range-progress {
  height: 5px;
  background-color: #a12121;
}

.body_player input[type='range']::-moz-range-track {
  height: 5px;
  background-color: #c3c2c5;
}

.body_player input[type='range']::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #b9b9b9;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.volume-slider {
  width: 80px;
  cursor: pointer;
}
