.fb-lightbox-class{
    display: none;
    position: fixed;
    z-index: 12;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0 , 0 , 0.8);
}

/* Modal Content */
.fb-lightbox-content{
    position: relative;
    background-color: #fefefe;
    margin: auto ;
    width: 90%;
    top: 40px;
 }

/* The Close Button */
.fb-close-lightbox{
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
}

.fb-close-lightbox:hover,
.fb-close-lightbox:focus{
    color: #999;
    text-decoration: none;
    cursor: pointer;
}
