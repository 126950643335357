/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import 'functions';
@import 'variables';
@import 'mixins';
@import 'utilities';

// Layout & components
@import 'root';
@import 'reboot';
// @import 'type';
@import 'images';
@import 'containers';
@import 'grid';

@import 'tables';
@import 'forms';
@import 'buttons';
@import 'transitions';
@import 'dropdown';
@import 'button-group';
@import 'nav';
@import 'navbar';
@import 'card';
@import 'accordion';
// @import 'breadcrumb';
@import 'pagination';
// @import 'badge';
// @import 'alert';
// @import 'progress';
@import 'list-group';
@import 'close';
// @import 'toasts';
@import 'modal';
// @import 'tooltip';
// @import 'popover';
// @import 'carousel';
// @import 'spinners';
// @import 'offcanvas';
// @import 'placeholders';

// Helpers
@import 'helpers';

// Utilities
@import 'utilities/api';
// scss-docs-end import-stack
