$verdana: Verdana, Geneva, Tahoma, sans-serif;
$oswald: 'Oswald', sans-serif;
// $logofont: 'Righteous', cursive;

*,
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: $base-theme-bg;
}

main {
  min-height: calc(100vh - 330px);
}

span,
p,
a,
li,
div {
  font-size: 18px;
}

a {
  text-decoration: none;
  color: $fb-links;
}

a:hover {
  color: $fb-links-hover;
}

.max-width-300 {
  max-width: 300px;
}

.btn-frissbee {
  background-color: $fb-btn-bg;
  color: $fb-btn-color;
  fill: $fb-btn-color;
}

.btn-frissbee:hover {
  background-color: $fb-btn-bg-hover;
  color: $fb-btn-color-hover;
  fill: $fb-btn-color-hover;
}

.btn-frissbee-second {
  background-color: $fb-btn-second-bg;
  color: $fb-btn-second-color;
  border: 1px solid $fb-btn-second-border;
}

.btn-frissbee-second:hover {
  background-color: $fb-btn-second-bg-hover;
  color: $fb-btn-second-color-hover;
}

.fb-cursor-pointer {
  cursor: pointer;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.font-color-green,
.upload-info-message {
  color: green;
}

.font-color-green,
.font-color-red {
  font-weight: bold;
}

.font-color-red {
  color: $color-01;
}

.search-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.search-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fb-input-field-number {
  max-width: 60px;
  height: 34px;
}

.lable-input-number {
  min-width: 64px;
}

.container-dashboard-projects {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .container-dashboard-projects {
    display: grid;
    display: inline-grid;
    width: 100%;
  }

  .container-dashboard-project-name {
    margin-bottom: 8px;
  }
}
