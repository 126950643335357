.fb-top-downloads {
  width: 100%;
  height: auto;
}

.image-download {
  width: 100%;
  height: auto;
}

.container-single-download {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.single-download {
  height: 100%;
  display: grid;
}
