$fb-font-color-theme: #4b4b4b;
$fb-font-color-theme-hover: #f7f7f7;
    
.fb-theme-overview{
    cursor: pointer;
}

.fb-theme-mySlides{
    display: none;
    text-align: center;
}

.fb-theme-img{
    vertical-align: middle;
    border: 1px solid #000000;
}

/* Slideshow container */
.fb-theme-slideshow-container{
    width: 100%;
    position: relative;
    margin: auto;
    background-color: #fff;
}

/* Next & previous buttons */
.fb-theme-prev, .fb-theme-next{
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: $fb-font-color-theme;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: #eaeaea;
}

/* Position the "next button" to the right */
.fb-theme-next{
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.fb-theme-prev:hover, .fb-theme-next:hover{
    background-color: rgba(0,0,0,0.6);
    color: $fb-font-color-theme-hover ;
    text-decoration: none;
}

/* Caption text */
.fb-theme-text{ 
    color: #922525;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

/* Number text (1/3 etc) */
.fb-theme-numbertext{
    color: $fb-font-color-theme;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* The dots/bullets/indicators */
.fb-theme-dot{
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.fb-theme-active, .fb-theme-dot:hover{
    background-color: #717171;
}


/* Fading animation */
.fb-theme-fade{
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

.fb-image-personenliste-kurzinfo{
    width: auto;
    height: 170px;
    margin-right: auto;
    margin-left: auto;
    display: block;
}

@-webkit-keyframes fade{
    from{
        opacity: .4
      }
    to{
        opacity: 1
      }
    }

@keyframes fade{
    from{
        opacity: .4
      }
    to{
        opacity: 1
      }
}
// On smaller screens, decrease text size
@media only screen and (max-width: 300px){
  .fb-theme-prev, .fb-theme-next,.fb-theme-text{
    font-size: 11px;
  }
}