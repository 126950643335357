.top-home-vw {
  background-image: url('./../../images/home/tophome.jpg');
  height: 420px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.newest-projects {
  background-image: url('./../../images/home/quellcode_1c.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.thinking-about-code {
  background-image: url('./../../images/home/thinking-code.png');
  height: 204px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

.thinking-code-container {
  margin: -130px 0 0 220px;
}

@media screen and (max-width: 992px) {
  .thinking-code-container,
  .thinking-about-code {
    display: none;
  }
}
