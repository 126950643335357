.fb-footer {
  background-color: $base-theme-footer-bg;
}

.fb-footer-text {
  color: $fb-footer-text;
  font-size: 14px;
}

.fb-footer-text-list {
  margin-top: 4px;
}

.footer-image {
  background-image: url('./../../images/overall/footer-image.jpg');
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
