.fb-form-login-register {
  max-width: 800px;
  min-width: 480px;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}
