.fb-section-work {
  background-image: url('./../../images/home/quellcode_1c.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-work {
  max-width: 306px;
  height: auto;
}

.fb-list-box {
  display: flex;
}

.fb-list-box-container {
  width: 707px;
}

.container-filter {
  width: 900px;
}

.fb-list-box-content {
  margin-left: 20px;
}

.fb-list-box-technology {
  color: #777777;
}

.work-icons-section {
  position: relative;
  margin-top: -28px;
}

.fb-icon-color {
  fill: $fb-icon-color;
}

.fb-pc-icon-work-1,
.fb-pc-icon-work-2,
.fb-pc-icon-work-3,
.fb-pc-icon-work-4 {
  padding: 0 4px 0 4px;
  transition: all 700ms;
}

.fb-pc-icon-work-1 {
  font-size: 28px;
}

.fb-pc-icon-work-2 {
  font-size: 48px;
}

.fb-pc-icon-work-3 {
  font-size: 68px;
}

.fb-pc-icon-work-4 {
  font-size: 88px;
}

.container-filter {
  margin: 0 auto;
  position: relative;
}

.filter-title,
.filter-reset {
  background-color: #d8d8d8;
  border: 1px solid #b6b6b6;
  text-align: center;
}

.filter-title {
  padding: 4px 20px;
}

.filter-title:hover,
.filter-reset:hover {
  background-color: #cecece;
}

.filter-title:focus,
.filter-reset:focus {
  -webkit-box-shadow: 0 0 0 0 transparent;
  box-shadow: none;
}

.filter-reset {
  padding: 0 6px 2px;
  font-size: 14px;
}

.container-collapse-btn {
  text-align: right;
}

.filter-checkbox {
  font-size: 14px;
}

.count-projekts {
  font-size: 12px;
  margin: 5px 0 0 -10px;
}

ul.pagination {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.pagination-body {
  height: 60px;
}

.pagination-page-item,
.pagination-page-item-default {
  margin: 0 2px 0 2px;
  background-color: transparent;
  height: 100%;
}

.pagination-page-item-small {
  height: 30px;
  background-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.active-page,
.active-page-small {
  color: $color-07;
  background-color: $color-01;
}

.active-page {
  padding: 14px 22px;
  margin-top: -6px;
}

.active-page-small {
  padding: 4px 14px;
}

.active-page:hover,
.active-page-small:hover {
  background-color: $color-01b;
  color: $color-17;
}

.no-active-page,
.no-active-page-small,
.default-active-page,
.default-active-page-small {
  background-color: rgb(247, 247, 247);
}

.no-active-page,
.default-active-page {
  padding: 8px 22px;
}

.no-active-page-small,
.default-active-page-small {
  padding: 4px 14px;
}

.font-size-14 {
  font-size: 14px;
}

.no-active-page:hover,
.no-active-page-small:hover {
  background-color: $color-01b;
  color: $color-17;
}

.page-container-no-active {
  margin-top: 6px;
}

@media screen and (max-width: 992px) {
  .fb-pc-icon-work-1 {
    font-size: 20px;
  }

  .fb-pc-icon-work-2 {
    font-size: 40px;
  }

  .fb-pc-icon-work-3 {
    font-size: 70px;
  }

  .fb-pc-icon-work-4 {
    font-size: 100px;
  }

  .container-filter {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .fb-list-box-container {
    margin: 0 0;
  }

  .fb-list-box-content {
    margin-top: 20px;
    margin-left: 0;
  }

  .fb-list-box {
    display: block;
    text-align: center;
  }

  .image-work {
    max-width: 100%;
    height: auto;
  }

  .image-download {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .fb-pc-icon-work-1 {
    font-size: 12px;
  }

  .fb-pc-icon-work-2 {
    font-size: 20px;
  }

  .fb-pc-icon-work-3 {
    font-size: 30px;
  }

  .fb-pc-icon-work-4 {
    font-size: 40px;
  }

  .active-page {
    padding: 8px 14px;
    margin-top: -4px;
  }

  .no-active-page,
  .default-active-page {
    padding: 4px 10px;
  }

  .pagination-page-item,
  .pagination-page-item-default {
    margin: 0 1px;
  }
}
