.fb-header {
  background-color: $base-theme-header-bg;
}

.header-link:hover {
  background-color: $base-theme-red;
}

.dashboard-link {
  font-size: 16px;
}
