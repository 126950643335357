.fb-input-field {
  min-width: 250px;
  max-width: 520px;
}

.fb-input-field-sm {
  max-width: 160px;
}

.success-message {
  color: green;
  font-weight: bold;
}

.fb-preview-image {
  height: 120px;
  width: auto;
  cursor: pointer;
}

.fb-subtitle-project {
  color: $fb-subtitle-project;
}

.projects-date {
  font-size: 12px;
}

.project-preview-image {
  height: auto;
  width: 100%;
}

.fb-preview-image-media {
  max-height: 80px;
  width: auto;
  cursor: pointer;
}

.input-select-images {
  max-width: 200px;
}

.resize-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 99;
  top: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
}

.resize-image,
.resize-video {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

.close-btn {
  position: absolute;
  background-color: transparent;
  height: 2.8rem;
  width: 2.8rem;
  right: 10px;
  top: 10px;
  cursor: pointer;
  border: 1px solid rgb(179, 179, 179);
  border-radius: 4px;
}

.close-svg {
  height: 2.6rem;
  fill: rgb(241, 241, 241);
  z-index: 100;
}
