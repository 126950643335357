.fb-about-me-section{
  background-image: url('./../../images/about/about-top-bg.jpg');

  .fb-profil-image{
    width: 240px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }

  .fb-opacity-5-dark{
    background: rgba(0, 0, 0, 0.5);
  }

  .fb-about-font-color{
    color: $fb-about-font-color;
  }

  @media screen and (max-width: 992px){
    .fb-about-info{
        margin-top: 40px;
    }
  }
}

.fb-personal-infos-section, .fb-private-projectes-section{
  .fb-icon-color{
    font-size: 20px;
  }
}

